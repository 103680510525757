export enum SocialLinks {
  Instagram = 'https://www.instagram.com/mindway.app/',
  Facebook = 'https://www.facebook.com/people/Mindway/61555945144541/',
  Twitter = '#',
  Reddit = '#',
}
export enum StoresLinks {
  AppleStore = 'https://apps.apple.com/us/app/mindway-stop-overthinking/id6475034455',
  GooglePlay = 'https://play.google.com/store/apps/details?id=com.mindwayhealth.app',
}
