import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { PlatformType } from './HowToSection';

interface PlatformSelectorProps {
  platform: PlatformType;
  onSelect: Dispatch<SetStateAction<PlatformType>>;
  iosTabTitle: string;
  androidTabTitle: string;
}

const Container = styled.div`
  width: 100%;
  max-width: 11.625rem;
  border-radius: 3.24956rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
`;

const PlatformSection = styled.button<{ isActive: boolean }>`
  padding: 7px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 3.24956rem;
  background-color: ${({ isActive }) => (isActive ? '#FF7975' : '#fff')};
  cursor: pointer;
  border: none;
`;

const TextStyled = styled(Text)`
  white-space: nowrap;
`;

const PlatformSelector: FC<PlatformSelectorProps> = ({
  platform,
  onSelect,
  iosTabTitle,
  androidTabTitle,
}) => {
  const handleIOS = () => {
    onSelect('ios');
  };

  const handleAndroid = () => {
    onSelect('android');
  };

  const isAndroidAcive = platform === 'android';

  const isIOSAcive = platform === 'ios';

  return (
    <Container>
      <PlatformSection isActive={isIOSAcive} onClick={handleIOS}>
        <TextStyled type="bodyM" color={isIOSAcive ? 'light0' : 'black'}>
          {iosTabTitle}
        </TextStyled>
      </PlatformSection>
      <PlatformSection isActive={isAndroidAcive} onClick={handleAndroid}>
        <TextStyled type="bodyM" color={isAndroidAcive ? 'light0' : 'black'}>
          {androidTabTitle}
        </TextStyled>
      </PlatformSection>
    </Container>
  );
};

export default PlatformSelector;
