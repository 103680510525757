import React from 'react';

import GooglePlayLogo from 'assets/icons/googlePlay_badge.svg';

import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

const StyledLink = styled.a``;
const AppleStoreBadge = styled(GooglePlayLogo)`
  height: 40px;
  width: 130px;
`;

const GooglePlayLinkBtn = ({ ...props }) => (
  <StyledLink href={StoresLinks.GooglePlay} target="_blank" {...props}>
    <AppleStoreBadge />
  </StyledLink>
);

export default GooglePlayLinkBtn;
